import { useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Link from 'next/link';
import { useTranslation } from '@hooks';
import { useAuth } from 'context/AuthContext';
import { NewCloseButton } from '@components/ui';
import { Caption, Caption2 } from '@components/styles/Typography';

const FIXED_TOP_ITEMS_HEIGHT = '83px';
const AccountMenu = ({
    activeMenu,
    alignBottom,
    fixed,
    hideTitleWrapper,
    closeAccountModal,
    modalPadding,
    menuItemCount,
    rightModalIsOpen,
}) => {
    const { t } = useTranslation();
    const { signout, currentUser } = useAuth();
    const router = useRouter();

    const signOut = () => {
        signout();
        if (rightModalIsOpen) {
            closeAccountModal();
        }
    };

    useEffect(() => {
        if (!currentUser) {
            router.push('/login');
        }
    }, [currentUser]);

    return (
        <MenuWrapper isFixed={fixed}>
            {!hideTitleWrapper && (
                <TitleWrapper>
                    <Caption2>{t('account')}</Caption2>
                    <NewCloseButton close={closeAccountModal} />
                </TitleWrapper>
            )}
            <MenuItemsContainer modalPadding={modalPadding}>
                <MenuItem
                    isSelected={activeMenu === 'orders'}
                    data-testid="order-button"
                    onClick={closeAccountModal}
                >
                    <Link href="/account/orders" passHref>
                        <Caption>{t('myOrders')}</Caption>
                        <Caption2>
                            {activeMenu === 'orders' && menuItemCount
                                ? `(${menuItemCount})`
                                : ''}
                        </Caption2>
                    </Link>
                </MenuItem>
                <MenuItem
                    data-testid="favorites-button"
                    isSelected={activeMenu === 'favorites'}
                    onClick={closeAccountModal}
                >
                    <Link href="/account/favorites" passHref>
                        <Caption>{t('myFavorites')}</Caption>
                        <Caption2>
                            {activeMenu === 'favorites' && menuItemCount
                                ? `(${menuItemCount})`
                                : ''}
                        </Caption2>
                    </Link>
                </MenuItem>
                <MenuItem
                    data-testid="address-button"
                    isSelected={activeMenu === 'address'}
                    onClick={closeAccountModal}
                >
                    <Link
                        to="/account/address"
                        href="/account/address"
                        passHref
                    >
                        <Caption>{t('myAddresses')}</Caption>
                        <Caption2>
                            {activeMenu === 'address' && menuItemCount
                                ? `(${menuItemCount})`
                                : ''}
                        </Caption2>
                    </Link>
                </MenuItem>
                <MenuItem
                    isSelected={activeMenu === 'coupons'}
                    onClick={closeAccountModal}
                >
                    <Link href="/account/coupons" passHref>
                        <Caption>{t('myDiscountCoupons')}</Caption>
                        <Caption2>
                            {activeMenu === 'coupons' && menuItemCount
                                ? `(${menuItemCount})`
                                : ''}
                        </Caption2>
                    </Link>
                </MenuItem>
                {/*
                <MenuItem
                    isSelected={activeMenu === 'cards'}
                    onClick={closeAccountModal}
                >
                    <Link href="/account/creditCards" passHref>
                        <Caption2>{t('savedCreditCards')}</Caption2>
                    </Link>
                </MenuItem> */}
                <MenuItem
                    onClick={closeAccountModal}
                    isSelected={activeMenu === 'info'}
                >
                    <Link href="/account/info" passHref>
                        <Caption2>{t('profileInformations')}</Caption2>
                    </Link>
                </MenuItem>
                <MenuItem
                    onClick={closeAccountModal}
                    isSelected={activeMenu === 'changePassword'}
                >
                    <Link href="/account/changePassword" passHref>
                        <Caption2>{t('changePassword')}</Caption2>
                    </Link>
                </MenuItem>
                <br />
                <br />
                <MenuItem alignBottom={alignBottom} onClick={closeAccountModal}>
                    <Link to="/faq" href="/faq" passHref>
                        <Caption color="bgGray50">{t('help')}</Caption>
                    </Link>
                </MenuItem>
                <MenuItem onClick={signOut}>
                    <Caption color="bgGray50" data-testid="logout">
                        {t('logOut')}
                    </Caption>
                </MenuItem>
            </MenuItemsContainer>
        </MenuWrapper>
    );
};

export const MenuWrapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    max-width: 100%;
    position: ${({ isFixed }) => isFixed && 'fixed'};
    margin-top: ${({ isFixed }) => isFixed && '5px'};

    @media (max-width: 1024px) {
        position: unset;
        min-height: unset;
        width: 100%;
        margin: ${({ type }) =>
            type === 'kvkk'
                ? `${FIXED_TOP_ITEMS_HEIGHT} 20px 20px`
                : type === 'membership'
                ? `160px 20px 20px`
                : `auto`};
    }
`;

export const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 100px;
    padding: 42px 50px 0 30px;

    @media (max-width: 1024px) {
        padding: 30px;
    }
`;

const MenuItemsContainer = styled.div`
    width: 100%;
    flex: 1;
    padding: ${({ modalPadding }) => (modalPadding ? '0 60px' : '0')};
    gap: 20px;

    @media (max-width: 1024px) {
        padding: 50px 30px 0 30px;
    }
`;
export const MenuItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    margin-top: ${({ alignBottom }) => alignBottom && '40px'};
    margin-bottom: 20px;

    span {
        margin-bottom: -3px;
        font-weight: ${({ theme, isSelected }) =>
            isSelected ? theme.fontWeights.bold : theme.fontWeights.regular};

        :hover {
            color: ${({ theme }) => theme.colors.primary};
        }
    }

    @media (max-width: 1024px) {
        padding: 0;
        margin-top: 0;
    }
`;

export default AccountMenu;
