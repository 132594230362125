import { useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from 'react-use';
import Modal from 'react-modal';
import styled from 'styled-components';

import fetcher from '@utils/fetcher';
import { useTranslation } from '@hooks';
import { useModal } from '@context/ModalContext';
import { DesktopSearchInput, Suggestion } from '@components/common';
import { NewCloseButton, RightModal, SliderProductCard } from '@components/ui/';
import { Caption, Caption2, Subtitle3 } from '@components/styles/Typography';

const getSearchResult = async (searchQuery) => {
    const options = {
        method: 'GET',
    };
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}v1/meta/auto-complete?keyword=${searchQuery}`,
        options,
    );
    return res.json();
};

const getPopularSearches = async () => {
    const options = {
        method: 'GET',
    };
    const res = await fetcher('v1/meta/popular-keyword', options);
    return res.data;
};
Modal.setAppElement('#__next');

const Search = () => {
    const { t, locale } = useTranslation();
    const router = useRouter();
    const { closeSearchModal, searchModalIsOpen } = useModal();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchHistory, setSearchHistory] = useLocalStorage('searchHistory');

    const { data } = useQuery(
        ['search suggestions', searchQuery],
        () => getSearchResult(searchQuery),
        {
            enabled: searchQuery.length > 2,
        },
    );
    const { data: popularSearches } = useQuery(
        ['popularSearches', searchQuery],
        () => getPopularSearches(),
        {enabled: !!searchModalIsOpen},
    );

    const products = data?.data?.products;
    const suggestions = data?.data?.categories.slice(0, 5);

    const search = (event, slug, isSubmit) => {
        event.preventDefault();
        if (searchHistory) {
            if (!searchHistory.includes(searchQuery.toLowerCase())) {
                setSearchHistory(
                    [searchQuery.toLowerCase(), ...searchHistory].slice(0, 1),
                );
            }
        } else {
            setSearchHistory([searchQuery]);
        }
        if (isSubmit) {
            router.push({
                pathname: `/${t('allSlug')}`,
                query: { keyword: slug },
            });
        } else {
            router.push(slug);
        }

        return setSearchQuery('');
    };

    return (
        <RightModal
            isOpen={searchModalIsOpen}
            closeModal={closeSearchModal}
            width="44%"
            maxWidth="670px"
            height="100vh"
            overflowY="auto"
        >
            <Container>
                <TitleWrapper>
                    <Caption2>{t('search')}</Caption2>
                    <NewCloseButton close={closeSearchModal} />
                </TitleWrapper>
                <SearchItemsContainer>
                    <DesktopSearchInput
                        search={search}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                    />
                    <AutoCompleteArea>
                        {!searchQuery && (
                            <>
                                <SubTitleWrapper>
                                    <Subtitle3>
                                        {t('popularSearches')}
                                    </Subtitle3>
                                </SubTitleWrapper>
                                {popularSearches?.length > 0 &&
                                    popularSearches.slice(0, 4).map((item) => (
                                        <PopularSearchItem
                                            key={item?.name[locale]}
                                            data-testid="popular-search-item"
                                            onClick={() =>
                                                router.push(
                                                    `/${item?.slug[locale]}`,
                                                )
                                            }
                                        >
                                            <Caption color="bgGray50" uppercase>
                                                {item?.name[locale]}
                                            </Caption>
                                        </PopularSearchItem>
                                    ))}
                            </>
                        )}

                        <ColumnWrapper>
                            {suggestions?.length > 0 && (
                                <Suggestions>
                                    {suggestions?.map((category, c) => (
                                        // eslint-disable-next-line react/jsx-no-useless-fragment
                                        <>
                                            {c <= 3 && (
                                                <Suggestion
                                                    key={category.slug.en}
                                                    search={search}
                                                    slug={`/${category.slug[locale]}`}
                                                    shouldBeBold={searchQuery.toLowerCase()}
                                                    text={category.name[
                                                        locale
                                                    ].toLowerCase()}
                                                    parentCategory={
                                                        category?.parent?.name[
                                                            locale
                                                        ]
                                                    }
                                                />
                                            )}
                                        </>
                                    ))}
                                </Suggestions>
                            )}
                            {searchQuery &&
                                (!suggestions || suggestions?.length === 0) && (
                                    <ListAllButton
                                        onClick={(e) =>
                                            search(e, searchQuery, 'submit')
                                        }
                                    >
                                        <Caption color="primaryVariant">
                                            {t('all')} “{searchQuery}”
                                            {t('listResult')}
                                        </Caption>
                                    </ListAllButton>
                                )}
                        </ColumnWrapper>

                        {products?.length > 0 && (
                            <ProductsWrapper>
                                <SubTitleWrapper>
                                    <Subtitle3>
                                        {t('relatedProducts')}
                                    </Subtitle3>
                                </SubTitleWrapper>
                                <ProductSuggestions>
                                    {products?.slice(0, 4).map((product) => (
                                        <SliderProductCard
                                            key={product.id}
                                            product={product}
                                        />
                                    ))}
                                </ProductSuggestions>
                            </ProductsWrapper>
                        )}
                    </AutoCompleteArea>
                </SearchItemsContainer>
            </Container>
        </RightModal>
    );
};

const Container = styled.div`
    height: 100%;
`;
const SearchItemsContainer = styled.div`
    padding: 30px 65px;
    @media (max-width: 1024px) {
        padding: 0;
        height: unset;
    }
`;
const SubTitleWrapper = styled.div`
    margin: 0 0 20px 0;
`;
const PopularSearchItem = styled.div`
    margin-top: 10px;
    text-transform: uppercase;
    cursor: pointer;

    span {
        :hover {
            color: ${({ theme }) => theme.colors.primary};
            text-decoration: underline;
        }
    }
`;
export const TitleWrapper = styled.div`
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    padding: 27px 40px 27px 30px;

    @media (max-width: 1024px) {
        padding: 0;
    }
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
    height: ${({ height }) => height && height};
    margin-bottom: 30px;

    @media (max-width: 1024px) {
        /* padding: 0 20px 20px 30px; */
        h3 {
            margin-top: 45px;
        }
    }
`;

const AutoCompleteArea = styled.div`
    height: 100%;

    @media (max-width: 1024px) {
        justify-content: space-between;
    }
`;
const Suggestions = styled.div`
    margin-top: ${({ marginTop }) => marginTop && '60px'};
`;
const ProductsWrapper = styled.div`
    margin-top: 20px;
`;
const ListAllButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    margin-top: 48px;
    padding-left: 0;
`;
const ProductSuggestions = styled.div`
    display: grid;
    grid-template-columns: 220px 200px;

    > div {
        width: 200px;
        margin-right: 24px;

        @media (max-width: 768px) {
            margin-right: 15px;
            width: 165px;
        }
    }

    @media (max-width: 768px) {
        grid-template-columns: 180px 165px;
        margin-right: -15px;
        margin-left: -15px;
    }
`;

export default Search;
