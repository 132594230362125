import { useModal } from 'context/ModalContext';
import { MenuIcon } from 'components/icons';
import styled from 'styled-components';

const MenuButton = ({ color }) => {
    const { openLeftMenuModal } = useModal();
    return (
        <Button type="button" onClick={openLeftMenuModal}>
            <MenuIcon
                data-testid="menu-button"
                fill={color || '#fff'}
                width="20px"
            />
        </Button>
    );
};

const Button = styled.div`
    height: 40px;
    width: 40px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default MenuButton;
