import styled from 'styled-components';
import Link from 'next/link';

const Logo = ({ width, newsletter }) => (
    <Link href="/" to="/" passHref>
        <Container>
            <StyledLogo
                src="https://cloud.keikei.com/cdn/fervente/fervente.png"
                alt="fervente logo"
                width={width}
                newsletter={newsletter}
                height="auto"
            />

            <Span>Fast Fashion Wholesale</Span>
        </Container>
    </Link>
);
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
const StyledLogo = styled.img`
    width: ${(props) => props.width || '200px'};
    min-width: ${(props) => props.newsletter && '300px'};
    cursor: pointer;
    @media (max-width: 1024px) {
        width: 150px;
    }
    @media (max-width: 768px) {
        width: 174px;

        /* padding: 15px 20px; */
    }
`;

const Span = styled.span`
    font-family: var(--futura-font);
    line-height: 18px;
    font-size: 13px;
    letter-spacing: 4px;
    font-weight: 300;
    margin-top: 4px;

    @media (max-width: 768px) {
        font-size: 10px;
        color: #000;
        font-size: 10px;
        letter-spacing: 2.9px;
        margin-top: 2px;
    }
`;

export default Logo;
