import { colors, sizes } from './variables';

const theme = {
    colors,
    sizes,
    buttonTypes: {
        primary: {
            color: colors.secondaryVariant,
            background: colors.primary,
            border: `1px solid ${colors.primary}`,
            borderRadius: '0px',
            hover: {
                background: colors.primaryVariant,
                border: `1px solid ${colors.primaryVariant}`,
                color: colors.secondaryVariant,
            },
            disabled: {
                background: colors.bgGray50,
                color: colors.buttonDisabled,
                border: 'none',
            },
        },
        secondary: {
            color: colors.primary,
            background: 'transparent',
            border: `1px solid ${colors.primaryVariant}`,
            borderRadius: '0px',
            hover: {
                border: `1px solid ${colors.primary}`,
                color: colors.primary,
            },
            disabled: {
                border: `1px solid ${colors.buttonDisabled}`,
                color: colors.buttonDisabled,
                background: 'transparent',
            },
        },
        gray: {
            color: colors.primary,
            background: colors.secondaryVariant,
            border: 'none',
            borderRadius: '0px',
            hover: {
                background: colors.tertiaryVariant,
                border: 'none',
                color: colors.primary,
            },
            disabled: {
                background: colors.secondaryVariant,
                color: colors.buttonDisabled,
                border: 'none',
            },
        },
    },
    buttonSizes: {
        xsmall: {
            width: 'max-content',
            height: 'auto',
            fontSize: sizes.xs,
            padding: '8px 7px',
            letterSpacing: '1px',
        },
        small: {
            width: 'max-content',
            height: 'auto',
            fontSize: sizes.xs,
            padding: '8px 16px',
            letterSpacing: '1px',
        },
        medium: {
            width: 'max-content',
            height: 'auto',
            fontSize: sizes.s,
            padding: '10px 20px',
            letterSpacing: '2px',
        },
        large: {
            width: 'max-content',
            height: 'auto',
            fontSize: sizes.m,
            padding: '12px 24px',
            letterSpacing: '2px',
        },
        xlarge: {
            width: '345px',
            height: 'auto',
            fontSize: sizes.m,
            padding: '17px 0',
            letterSpacing: '2px',
            mobileWidth: '100%',
        },
        icon: {
            width: '32px',
            height: '32px',
            fontSize: sizes.m,
            letterSpacing: '2px',
            padding: '0px',
        },
    },
    fontWeights: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
    },

    zIndices: {},
    breakPoints: {},
};

export default theme;
