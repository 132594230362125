import styled from 'styled-components';
import Link from 'next/link';
import { useTranslation } from '@hooks';
import { Caption, Caption2, Subtitle3 } from '@components/styles/Typography';

const CartModalProductInfo = ({
    amount,
    discountedPrice,
    image,
    name,
    stock,
    slug,
    withBorder,
    addToCartModal,
    showDescription,
    sizes,
}) => {
    const { t, useCurrency, locale } = useTranslation();
    const priceFormatted = useCurrency(discountedPrice);
    return (
        <Link href={`/${t('productSlug')}/${slug[locale]}`} passHref>
            <Wrapper
                outOfStock={stock < 1}
                withBorder={withBorder}
                addToCartModal={addToCartModal}
            >
                <img src={image} alt={name} />
                <Description showDescription={showDescription}>
                    <ModalProductTitle addToCartModal={addToCartModal}>
                        {name}
                    </ModalProductTitle>
                    <DescriptionBottom>
                        <>
                            <div>
                                <Caption2 color="primaryVariant">
                                    {t('productSizeTitle')}:{' '}
                                </Caption2>
                                <Caption2>
                                    {sizes}
                                    <Caption2 color="bgGray50">
                                        {' '}
                                        {amount / 3} of each sizes ({amount / 3}
                                        -{amount / 3}-{amount / 3})
                                    </Caption2>
                                </Caption2>
                            </div>
                            <div>
                                <Caption2 color="primaryVariant">
                                    {t('productAmountTitle')}:{' '}
                                </Caption2>
                                <Caption2>
                                    {amount / 3} Pack , {amount} Units
                                </Caption2>
                            </div>
                        </>
                    </DescriptionBottom>
                    <ModalProductPrice>{priceFormatted}</ModalProductPrice>
                    {stock === 0 && (
                        <OutOfStock>{t('cartStockOut')}</OutOfStock>
                    )}
                </Description>
            </Wrapper>
        </Link>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    background-color: ${({ outOfStock, theme }) =>
        outOfStock ? `${theme.colors.bgGray25}` : `${theme.colors.secondary}`};
    padding: 10px 10px;

    img {
        width: 60px;
        height: 90px;
    }
`;
const Description = styled.div`
    margin-left: 10px;
    display: flex;
    height: 100%;
    flex-direction: column;
`;

const ModalProductTitle = styled(Subtitle3)`
    text-transform: capitalize;
`;

const ModalProductPrice = styled(Caption)`
    margin: 5px 0;
`;

const DescriptionBottom = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px 0;
`;

const OutOfStock = styled(Caption)`
    margin-top: 2px;
    color: ${({ theme }) => theme.colors.error};
`;
export default CartModalProductInfo;
