import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useWindowScroll } from 'react-use';

import { useAuth } from '@context/AuthContext';
import { useModal } from '@context/ModalContext';
import { useAccount } from '@context/AccountContext';
import { Logo } from '@components/common';
import { FilledSearchIcon, ProfileIcon, SearchIcon } from '@components/icons';
import useIsScrolling from '@hooks/useIsScrolling';
import truncateString from '@utils/truncateString';
import { Caption } from '@components/styles/Typography';
import MenuButton from '../MenuButton';
import CartButton from '../../cart/CartButton';

const MobileHeader = ({ isFixed = true }) => {
    const router = useRouter();
    const { currentUser } = useAuth();
    const { userData } = useAccount();
    const { openSearchModal, searchModalIsOpen } = useModal();
    const isScrolling = useIsScrolling();
    const { y } = useWindowScroll();

    const onCartButtonClick = () => {
        router.push('/cart');
    };
    const isListing = router.pathname === '/[category]';
    const isTransparent = isListing ? isScrolling && y > 150 : isScrolling;
    return (
        <MobileHeaderContainer isTransparent={isTransparent} isFixed={isFixed}>
            <MenuAndLogoWrapper>
                <MenuButton color="#00000" />
                <Logo width="auto" />
            </MenuAndLogoWrapper>
            <AccountAndSearchWrapper>
                <Link href="/account" to="/account" passHref>
                    <MenuItem data-testid="account-button">
                        {currentUser && userData?.data?.data?.me?.first_name ? (
                            <Caption>
                                {truncateString(
                                    userData.data.data.me.first_name
                                        .charAt(0)
                                        .toUpperCase() +
                                    userData.data.data.me.first_name.slice(
                                        1,
                                    ),
                                    6,
                                )}
                            </Caption>
                        ) : (
                            <ProfileIcon fill="#000000" />
                        )}
                    </MenuItem>
                </Link>
                <MenuItem onClick={openSearchModal} data-testid="search-button">
                    {searchModalIsOpen ? (
                        <FilledSearchIcon />
                    ) : (
                        <SearchIcon fill="#000000" />
                    )}
                </MenuItem>
                <CartButton onCartButtonClick={onCartButtonClick} />
            </AccountAndSearchWrapper>
        </MobileHeaderContainer>
    );
};

export default MobileHeader;

const MobileHeaderContainer = styled.div`
    position: ${({ isFixed }) => (isFixed ? 'fixed' : 'relative')};
    width: 100%;
    height: 60px;
    top: 0;
    margin-top: ${({ isFixed }) => (isFixed ? '0' : '0')};
    padding: 0 15px;
    z-index: 99;
    display: flex;

    align-items: center;
    justify-content: space-between;
    background-color: ${({ isTransparent, theme }) =>
        isTransparent ? 'transparent' : `${theme.colors.secondary}`};
    -moz-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    transition-delay: 0.2s;

    @media (min-width: 1025px) {
        display: none;
    }
`;
const MenuAndLogoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
`;
const AccountAndSearchWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
const MenuItem = styled.div`
    display: flex;
    align-items: center;
`;
