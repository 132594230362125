import React, { useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation } from '@tanstack/react-query';
import AuthServices from '@services/AuthServices';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [cookie, setCookie, removeCookie] = useCookies(['user_token']);
    const [currentUser, setCurrentUser] = useState(cookie.user_token);
    const [basketToken, setBasketToken] = useState(null);
    const saveToken = (token) => {
        setCookie('user_token', token, {
            maxAge: 1296000,
        });
    };

    const loginMutation = useMutation({
        mutationFn: AuthServices.login,
        onSuccess: (data) => {
            if (data?.success === false) {
                return;
            }
            setCurrentUser(data.access_token);
            saveToken(data.access_token);
            setBasketToken(data.basket_hash);
        },
    });

    const registerMutation = useMutation({
        mutationFn: AuthServices.register,
        onSuccess: (data) => {
            if (data?.success === false) {
                return;
            }
            setCurrentUser(data.data.access_token);
            saveToken(data.data.access_token);
        },
    });

    const guestLoginMutation = useMutation({
        mutationFn: AuthServices.guestLogin,
        onSuccess: (data) => {
            if (data?.errors || data?.success === false) {
                return;
            }
            setCurrentUser(data.data.access_token);
            saveToken(data.data.access_token);
        },
    });

    const resetPasswordMutation = useMutation({
        mutationFn: AuthServices.resetPassword,
    });

    const changePasswordMutation = useMutation({
        mutationFn: AuthServices.changePassword,
    });

    const forgetPasswordMutation = useMutation({
        mutationFn: AuthServices.forgetPassword,
    });

    const signout = () => {
        removeCookie('user_token');
        setCurrentUser(null);
    };

    const value = useMemo(
        () => ({
            currentUser,
            loginMutation,
            registerMutation,
            resetPasswordMutation,
            changePasswordMutation,
            forgetPasswordMutation,
            guestLoginMutation,
            basketToken,
            signout,
        }),
        [
            currentUser,
            loginMutation,
            registerMutation,
            resetPasswordMutation,
            changePasswordMutation,
            forgetPasswordMutation,
            guestLoginMutation,
            basketToken,
            signout,
        ],
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};
