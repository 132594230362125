import Modal from 'react-modal';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { useTranslation } from '@hooks';
import { useCart } from '@context/CartContext';
import { useModal } from '@context/ModalContext';
import { CartModalProductInfo } from '@components/cart';
import { NewButton, NewCloseButton, RightModal } from '@components/ui';
import { TitleWrapper } from '@components/common/Search';
import { Caption2 } from '@components/styles/Typography';

Modal.setAppElement('#__next');

const CartModal = ({ currentUser, hideTitleWrapper, isOpen, closeModal }) => {
    const { t, locale } = useTranslation();
    const { products } = useCart();
    const { closeCartModal } = useModal();
    const router = useRouter();

    const goCheckout = () => {
        if (currentUser) {
            router.push('/checkout');
        } else {
            router.push({
                pathname: '/login',
                query: { cb: '/checkout' },
            });
        }
    };

    const goToCart = () => {
        router.push('/cart');
    };

    return (
        <RightModal isOpen={isOpen} closeModal={closeModal}>
            {!hideTitleWrapper && (
                <TitleWrapper>
                    <Caption2>{t('cartTitle')}</Caption2>
                    <NewCloseButton close={closeCartModal} />
                </TitleWrapper>
            )}
            {products.length > 0 ? (
                <>
                    <CartItemWrapper>
                        {products.map((item) => (
                            <CartModalProductInfo
                                key={item.id}
                                withBorder
                                name={item.name[locale]}
                                sizes={item.sizes[0]
                                    ?.map((size) => size.size_label)
                                    .join(', ')}
                                price={item.pack_price}
                                image={item.images[0].urls.thumb}
                                size={item.size_label}
                                amount={item.quantity}
                                stock={item.stock}
                                discountedPrice={item.discounted_pack_price}
                                slug={item.slug}
                                addToCartModal
                            />
                        ))}
                    </CartItemWrapper>
                    <CartModalBottomContainer>
                        <Buttons>
                            <NewButton
                                type="primary"
                                size="xlarge"
                                content={t('buyNow')}
                                handleClick={goCheckout}
                            />
                            <NewButton
                                type="secondary"
                                size="xlarge"
                                content={t('goToCart')}
                                handleClick={goToCart}
                                data-testid="go-to-cart-button"
                            />
                        </Buttons>
                    </CartModalBottomContainer>
                </>
            ) : (
                <CartNoItem>
                    <Caption2>{t('cartNoItem')}</Caption2>
                </CartNoItem>
            )}
        </RightModal>
    );
};

const CartItemWrapper = styled.div`
    padding: 35px 50px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 14px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 14px;
        background-color: ${({ theme }) => theme.colors.bgGray50};
    }

    @media (max-width: 1024px) {
        padding: 20px 30px;
    }
`;
const CartModalBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.secondary};
    margin-top: auto;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    gap: 24px;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.secondary};
    width: 100%;
    padding: 50px 40px;

    @media (max-width: 1024px) {
        flex-direction: column-reverse;
        align-items: center;
        position: absolute;
        bottom: 10px;
        padding: 0;
        button {
            margin: 10px 0;
        }
    }
`;

const CartNoItem = styled.div`
    padding: 20px 60px;
`;

export default CartModal;
