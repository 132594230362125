import React from 'react';
import styled from 'styled-components';
import WhatsappIcon from '@components/icons/WhatsappIcon';
import { useRouter } from 'next/router';

const URL = 'https://wa.me';

const Whatsapp = ({ number, message, ...props }) => {
    const router = useRouter();
    const formattedNumber = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');
    let url = `${URL}/${formattedNumber}`;

    if (message) {
        url += `?text=${encodeURI(message)}`;
    }

    const isCartOrCheckout =
        router.pathname === '/cart' || router.pathname === '/checkout' || router.pathname.includes('/product');

    return (
        <Wrapper
            type="button"
            onClick={() => {
                window.open(url);
                window.dataLayer.push({
                    event: 'wp_click',
                });
            }}
            isCartOrCheckout={isCartOrCheckout}
            {...props}
        >
            <Container>
                <WhatsappIcon />
                <Message>1</Message>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;

    @media (max-width: 768px) {
        bottom: ${({ isCartOrCheckout }) =>
        isCartOrCheckout ? '180px' : '30px'};
        right: 10px;
    }
`;

const Container = styled.div`
    position: relative; 
   
`;
const Message = styled.span`

        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -5px;
        right: -20px;
        transform: translate(-50%, -50%);
        font-size: 12px;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: red;
    
`;
export default Whatsapp;
