import * as React from 'react';

const WhatsappIcon = (props) => (
    <svg
        width={56}
        height={56}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={56} height={56} rx={16} fill="#F4F4F4" />
        <g clipPath="url(#a)">
            <path
                d="M44.124 11.685C39.818 7.377 34.094 5.003 27.994 5 15.427 5 5.199 15.225 5.194 27.793a22.752 22.752 0 0 0 3.043 11.395L5.001 51l12.088-3.17a22.785 22.785 0 0 0 10.896 2.775h.01c12.566 0 22.796-10.226 22.801-22.794a22.656 22.656 0 0 0-6.672-16.126Zm-16.13 35.07h-.007a18.93 18.93 0 0 1-9.646-2.64l-.692-.411-7.173 1.88 1.915-6.99-.451-.717a18.895 18.895 0 0 1-2.897-10.083C9.047 17.348 17.548 8.85 28.002 8.85A18.829 18.829 0 0 1 41.4 14.406a18.83 18.83 0 0 1 5.546 13.404c-.004 10.446-8.506 18.946-18.951 18.946Z"
                fill="#E0E0E0"
            />
            <path
                d="m6.048 49.69 3.087-11.273A21.711 21.711 0 0 1 6.23 27.543c.005-11.994 9.766-21.751 21.76-21.751 5.82.002 11.284 2.268 15.392 6.38a21.616 21.616 0 0 1 6.367 15.388c-.005 11.994-9.767 21.752-21.758 21.752-.001 0 0 0 0 0h-.01a21.754 21.754 0 0 1-10.398-2.647L6.048 49.689Z"
                fill="url(#b)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.36 18.116c-.422-.938-.866-.956-1.267-.973-.328-.014-.704-.013-1.08-.013-.375 0-.985.141-1.501.705-.517.563-1.972 1.925-1.972 4.696s2.019 5.449 2.3 5.825c.282.375 3.896 6.243 9.62 8.5 4.758 1.877 5.726 1.504 6.759 1.41 1.032-.094 3.332-1.362 3.801-2.677.47-1.315.47-2.442.329-2.678-.141-.235-.517-.375-1.08-.657-.563-.282-3.332-1.644-3.848-1.832-.517-.188-.892-.282-1.267.282-.376.563-1.455 1.832-1.783 2.207-.329.376-.657.423-1.22.142-.564-.283-2.378-.877-4.53-2.795-1.674-1.493-2.804-3.337-3.133-3.9-.328-.564-.035-.869.248-1.15.252-.252.563-.657.845-.986.28-.329.374-.563.562-.939.188-.376.094-.705-.047-.986-.14-.282-1.235-3.067-1.736-4.18Z"
                fill="#fff"
            />
            <path
                d="M43.938 11.612c-4.256-4.258-9.915-6.605-15.944-6.608-12.425 0-22.535 10.108-22.54 22.532A22.49 22.49 0 0 0 8.462 38.8L5.264 50.476l11.949-3.133a22.526 22.526 0 0 0 10.77 2.743h.01c12.423 0 22.535-10.11 22.54-22.532a22.397 22.397 0 0 0-6.595-15.942ZM27.994 46.281h-.008a18.71 18.71 0 0 1-9.535-2.611l-.684-.406-7.091 1.859 1.893-6.91-.446-.71a18.678 18.678 0 0 1-2.864-9.966C9.263 17.211 17.667 8.81 28.001 8.81a18.612 18.612 0 0 1 13.244 5.492 18.614 18.614 0 0 1 5.483 13.25c-.005 10.327-8.409 18.729-18.734 18.729Z"
                fill="#fff"
            />
        </g>
        <defs>
            <linearGradient
                id="b"
                x1={27.899}
                y1={49.689}
                x2={27.899}
                y2={5.792}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#20B038" />
                <stop offset={1} stopColor="#60D66A" />
            </linearGradient>
            <clipPath id="a">
                <path
                    fill="#fff"
                    transform="translate(5 5)"
                    d="M0 0h45.798v46H0z"
                />
            </clipPath>
        </defs>
    </svg>
);

export default WhatsappIcon;
