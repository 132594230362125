const defaultLocalesConfig = {
    en: {
        lang: 'en',
        currency: 'USD',
        currencySymbol: '$',
        countryID: 232,
        label: 'United Kingdom',
        dialCode: '+44',
    },
    // dz: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 4,
    //     label: 'Algeria',
    //     dialCode: '+213',
    // },
    // au: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 14,
    //     label: 'Australia',
    //     dialCode: '+61',
    // },
    // at: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 3,
    //     label: 'Austria',
    //     dialCode: '+43',
    // },

    // az: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 16,
    //     label: 'Azerbaijan',
    //     dialCode: '+994',
    // },
    // bh: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 18,
    //     label: 'Bahrain',
    //     dialCode: '+973',
    // },
    // be: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 82,
    //     label: 'Deutschland',
    //     dialCode: '+49',
    // },
    // br: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 31,
    //     label: 'Brazil',
    //     dialCode: '+55',
    // },
    // bg: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 34,
    //     label: 'Bulgaria',
    //     dialCode: '+359',
    // },
    // ca: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 39,
    //     label: 'Canada',
    //     dialCode: '+1',
    // },
    // cn: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 45,
    //     label: 'China',
    //     dialCode: '+86',
    // },
    // cy: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 57,
    //     label: 'Cyprus',
    //     dialCode: '+357',
    // },
    // dk: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 59,
    //     label: 'Denmark',
    //     dialCode: '+45',
    // },
    // eg: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 65,
    //     label: 'Egypt',
    //     dialCode: '+20',
    // },
    // fi: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 74,
    //     label: 'Finland',
    //     dialCode: '+358',
    // },
    // fr: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 75,
    //     label: 'France',
    //     dialCode: '+33',
    // },

    // ge: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 81,
    //     label: 'Georgia',
    //     dialCode: '+995',
    // },
    // de: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 82,
    //     label: 'Germany',
    //     dialCode: '+49',
    // },
    // gr: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 85,
    //     label: 'Greece',
    //     dialCode: '+30',
    // },
    // hu: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 99,
    //     label: 'Hungary',
    //     dialCode: '+36',
    // },
    // in: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 104,
    //     label: 'India',
    //     dialCode: '+91',
    // },
    // id: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 102,
    //     label: 'Indonesia',
    //     dialCode: '+62',
    // },
    // ir: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 103,
    //     label: 'Iran',
    //     dialCode: '+353',
    // },
    // iq: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 104,
    //     label: 'Iraq',
    //     dialCode: '+964',
    // },
    // ie: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 105,
    //     label: 'Ireland',
    //     dialCode: '+353',
    // },
    // il: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 106,
    //     label: 'Israel',
    //     dialCode: '+972',
    // },
    // it: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 107,
    //     label: 'Italy',
    //     dialCode: '+39',
    // },
    // ke: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 113,
    //     label: 'Kenya',
    //     dialCode: '+254',
    // },
    // lb: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 121,
    //     label: 'Lebanon',
    //     dialCode: '+961',
    // },
    // md: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 144,
    //     label: 'Moldova',
    //     dialCode: '+373',
    // },
    // me: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 145,
    //     label: 'Montenegro',
    //     dialCode: '+382',
    // },
    // nl: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 156,
    //     label: 'Netherlands',
    //     dialCode: '+31',
    // },
    // pl: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 176,
    //     label: 'Poland',
    //     dialCode: '+48',
    // },
    // pt: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 177,
    //     label: 'Portugal',
    //     dialCode: '+351',
    // },

    // qa: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 179,
    //     label: 'Qatar',
    //     dialCode: '+974',
    // },
    // ro: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 181,
    //     label: 'Romania',
    //     dialCode: '+40',
    // },
    // rs: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 196,
    //     label: 'Serbia',
    //     dialCode: '+381',
    // },
    // ru: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 182,
    //     label: 'Russia',
    //     dialCode: '+7',
    // },
    // sa: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: 'USD',
    //     countryID: 194,
    //     label: 'Saudi Arabia',
    //     dialCode: '+966',
    // },
    // sk: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 200,
    //     label: 'Slovakia',
    //     dialCode: '+421',
    // },
    // si: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 201,
    //     label: 'Slovenia',
    //     dialCode: '+386',
    // },
    // es: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 207,
    //     label: 'Spain',
    //     dialCode: '+34',
    // },
    // se: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 213,
    //     label: 'Sweden',
    //     dialCode: '+46',
    // },
    // ch: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 214,
    //     label: 'Switzerland',
    //     dialCode: '+41',
    // },
    // sy: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 215,
    //     label: 'Syria',
    //     dialCode: '+963',
    // },
    // tr: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 225,
    //     label: 'Türkiye',
    //     dialCode: '+90',
    // },

    // ae: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 231,
    //     label: 'United Arab Emirates',
    //     dialCode: '+971',
    // },

    // us: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 233,
    //     label: 'United States',
    //     dialCode: '+1',
    // },
    // uz: {
    //     lang: 'en',
    //     currency: 'USD',
    //     currencySymbol: '$',
    //     countryID: 236,
    //     label: 'Uzbekistan',
    //     dialCode: '+998',
    // },
};
export default defaultLocalesConfig;
