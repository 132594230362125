import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const overlay = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0, 0.33)',
        zIndex: 999,
    },
};
const noOverlayClass = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0, 0)',
        zIndex: 999,
    },
};
const RightModal = ({
    children,
    isOpen,
    closeModal,
    noOverlay,
    width,
    maxWidth,
    height,
    overflowY,
}) => (
    <StyledModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={noOverlay ? noOverlayClass : overlay}
        contentLabel="Right Modal"
        overlayClassName="rightModal"
        className={isOpen ? 'slide-left' : 'slide-right'}
        closeTimeoutMS={700}
        width={width}
        maxWidth={maxWidth}
        height={height}
        overflowY={overflowY}
    >
        {children}
    </StyledModal>
);

export default RightModal;

const StyledModal = styled(Modal)`
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
    width: ${({ width }) => width || '600px'};
    max-width: ${({ maxWidth }) => maxWidth || '100%'};
    height: 100%;
    z-index: 999;
    background: ${({ theme }) => theme.colors.secondary};
    //border: ${({ theme }) => `1px solid ${theme.colors.bgGray50}`};
    display: flex;
    flex-direction: column;
    overflow-y: ${({ overflowY }) => overflowY || 'hidden'};
    transition: all 0.7s ease-in-out;

    @media (max-width: 1024px) {
        background: white;
        outline: none;
        display: block;
        width: 100%;
        height: ${({ height }) => height || 'calc(100vh - 50px)'};
        border-radius: 2px;
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.13);
        border: solid 1px #ececec;
        padding: 20px 30px;
    }
`;
