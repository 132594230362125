import styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Caption, Subtitle3, Text2 } from '@components/styles/Typography';

const MenuItem = ({
    color,
    count,
    homePageHeader,
    slug,
    name,
    sort,
    isSub,
    onHover,
    setActiveParent,
}) => {
    const router = useRouter();
    const { category } = router.query;
    const slugWithQueries = () => {
        if (sort !== 'newProduct' && sort !== undefined) {
            return `/${slug}?order=${sort}`;
        }
        return `/${slug}`;
    };

    const isSelected = `${category}` === slug;
    return (
        <MenuItemLink
            href={slugWithQueries()}
            onMouseEnter={onHover && onHover}
            onClick={setActiveParent}
            passHref
        >
            <MenuItemTitle color={color} isSelected={isSelected} isSub={isSub}>
                <div>
                    {isSelected ? (
                        <Subtitle3 isSub={isSub}>{name}</Subtitle3>
                    ) : (
                        <Text2 isSub={isSub} color={color && color}>
                            {name}
                        </Text2>
                    )}
                </div>
                <MenuItemCount
                    homePageHeader={homePageHeader}
                    isSub={isSub}
                    isSelected={isSelected}
                    color={!isSelected && 'success'}
                >
                    {count}
                </MenuItemCount>
            </MenuItemTitle>
        </MenuItemLink>
    );
};

const MenuItemLink = styled(Link)`
    @media (max-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`;
const MenuItemCount = styled(Caption)`
    position: absolute;
    top: -8px;
    right: -20px;
    transition: all 1s ease;
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
    border-bottom: none !important;
    color: ${({ theme, isSub, isSelected }) =>
        isSub
            ? isSelected
                ? theme.colors.primary
                : theme.colors.bgGray50
            : theme.colors.primary};

    &:hover ${MenuItemLink} {
        display: block;
    }
    @media (max-width: 1024px) {
        display: none;
        top: 8px;
        right: -15px;
    }
`;

const MenuItemTitle = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-bottom: 2px solid transparent;
    white-space: nowrap;

    img {
        padding-bottom: 8px;
    }

    :first-child {
        @media (min-width: 1025px) {
            border-bottom: ${({ isSelected }) =>
                isSelected ? '1px solid #000' : 'none'};
        }
    }
    span {
        padding-bottom: 1px;
        color: ${({ theme, isSelected }) =>
            isSelected ? theme.colors.primary : theme.colors.bgGray50};

        letter-spacing: ${({ isSelected }) => (isSelected ? '2px' : '1px')};

        @media (max-width: 1024px) {
            color: ${({ theme }) => theme.colors.primary};
            font-size: ${({ isSub }) => (isSub ? '10px' : '12px')};
            letter-spacing: ${({ isSelected }) => (isSelected ? '2px' : '1px')};
        }
    }
    @media (min-width: 1025px) {
        :hover {
            ${MenuItemCount} {
                display: block;
            }
        }
    }
    @media (max-width: 1024px) {
        padding: ${({ padding }) => padding || '8px 0'};
        margin: 0;
    }
`;
export default MenuItem;
