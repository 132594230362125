import fetcher from '@utils/fetcher';

const AuthServices = {
    login: ({ username, password, basketHash, lang, countryID }) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({
                grant_type: 'password',
                client_id: 2,
                client_secret: 'MaKcIkonl0yPrm9j8ofIAQAUOiFW66A7reJfWfzd',
                country_id: countryID,
                basket_hash: basketHash,
                username,
                password,
                lang,
                scope: '',
            }),
        };
        return fetcher('v1/oauth/token', options);
    },
    register: (formData) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email.toLowerCase(),
                password: formData.password,
                phone: `${formData.countryCode}${formData.phoneNumber}`,
                confirm_password: formData.password,
                user_agreement: formData.allowAgreement,
                sms_info: formData.allowPromotion,
                email_info: formData.allowPromotionEmail,
                lang: formData.lang,
                country_id: formData.countryID,
                ip_address: formData.ip,
                port: 80,
            }),
        };
        return fetcher('v1/oauth/register', options);
    },
    newsletter: (formData) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email.toLowerCase(),
                phone: formData.phone,
                country_id: formData.countryID,
                state: formData.cityID,
            }),
        };
        return fetcher('v1/user/newsletter', options);
    },
    changePassword: ({
        currentPassword,
        newPassword,
        confirmNewPassword,
        userToken,
    }) => {
        const options = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify({
                password: currentPassword,
                new_password: newPassword,
                confirm_password: confirmNewPassword,
            }),
        };
        return fetcher('v1/change-password', options, true);
    },
    forgetPassword: ({ email }) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({
                email,
            }),
        };
        return fetcher('v1/oauth/forgot-password', options);
    },
    resetPassword: ({ resetToken, password, passwordAgain }) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({
                password,
                confirm_password: passwordAgain,
            }),
        };
        return fetcher(`v1/oauth/reset-password/${resetToken}`, options);
    },
    guestLogin: ({ email, lang, countryID }) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({
                email,
                lang,
                country_id: countryID,
            }),
        };
        return fetcher('v1/oauth/guest-login', options);
    },
    // signout: () => fetcher('v1/oauth/logout', {}, true),
};

export default AuthServices;
