const staticMenuItems = [
    {
        id: 1,
        name: {
            en: 'All',
            ru: 'Оптом Одежда Для Женщины',
            tr: 'Toptan Elbise',
        },
        slug: {
            en: 'wholesale-clothing',
            ru: 'wholesale-clothing',
            tr: 'wholesale-clothing',
        },
        productCount: '',
    },
    {
        id: 2,
        name: {
            en: 'Dress',
            ru: 'Dress',
            tr: 'Dress',
        },
        slug: {
            en: 'dress',
            ru: 'dress',
            tr: 'dress',
        },
        productCount: '',
    },
    {
        id: 3,
        name: {
            en: 'New',
            ru: 'New',
            tr: 'Yeni',
        },
        slug: {
            en: 'new',
            ru: 'new',
            tr: 'yeni',
        },
        productCount: '',
    },
    {
        id: 4,
        name: {
            en: 'Plus Size',
            ru: 'Платья Большого Размера',
            tr: 'Büyük Beden',
        },
        slug: {
            en: 'plus-size-dress',
            ru: 'platya-bolsogo-razmera',
            tr: 'buyuk-beden-elbiseler',
        },
        productCount: '',
    },
    {
        id: 5,
        name: {
            en: 'Production Request',
            ru: 'Запрос На Производство',
            tr: 'Üretim Talebi',
        },
        slug: {
            en: 'production-request',
            ru: 'zapros-na-proizvodstvo',
            tr: 'uretim-talebi',
        },
        productCount: '',
    },
];

export default staticMenuItems;
