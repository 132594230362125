export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;
export const AW_TRACKING_ID = process.env.NEXT_PUBLIC_AW_ID;

export const pageview = (url) => {
    if (typeof window !== 'undefined') {
        window.gtag('config', GA_TRACKING_ID, {
            page_path: url,
        });
    }
};

export const event = ({
    action,
    category,
    label,
    value,
    items,
    transactionID,
    currency,
}) => {
    if (typeof window !== 'undefined') {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value,
            items,
            transaction_id: transactionID,
            currency,
        });
    }
};
