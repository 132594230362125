import React, { useContext, useMemo, useState } from 'react';

const ModalContext = React.createContext();

export function useModal() {
    return useContext(ModalContext);
}

export const ModalProvider = ({ children }) => {
    const [accountModalIsOpen, setAccountModalIsOpen] = useState(false);
    const [rightModalIsOpen, setRightModalIsOpen] = useState(false);
    const [leftMenuModalIsOpen, setLeftMenuModalIsOpen] = useState(false);
    const [cartModalIsOpen, setCartModalIsOpen] = useState(false);
    const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
    const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);
    const [addToCartModalIsOpen, setAddToCartModalIsOpen] = useState(false);
    const [productDetailModalIsOpen, setProductDetailModalIsOpen] = useState(
        false,
    );
    const [allCategoriesModalIsOpen, setAllCategoriesModalIsOpen] = useState(
        true,
    );
    function openRightModal() {
        setRightModalIsOpen(true);
    }
    function closeRightModal() {
        setRightModalIsOpen(false);
    }

    function openLeftMenuModal() {
        setLeftMenuModalIsOpen(true);
    }

    function openAddToCartModal(id) {
        setAddToCartModalIsOpen(id);
    }

    function closeAddToCartModal() {
        setAddToCartModalIsOpen(false);
    }

    function closeLeftMenuModal() {
        setLeftMenuModalIsOpen(false);
    }

    function closeAccountModal() {
        setAccountModalIsOpen(false);
    }
    const closeCartModal = () => {
        setCartModalIsOpen(false);
    };
    function openSearchModal() {
        closeCartModal();
        closeAccountModal();
        closeLeftMenuModal();
        setSearchModalIsOpen(true);
    }
    function closeSearchModal() {
        setSearchModalIsOpen(false);
    }
    const openCartModal = () => {
        closeSearchModal();
        closeAccountModal();
        closeLeftMenuModal();
        setCartModalIsOpen(true);
    };

    function openAccountModal() {
        closeCartModal();
        closeSearchModal();
        closeLeftMenuModal();
        setAccountModalIsOpen(true);
    }
    function openFilterModal() {
        setFilterModalIsOpen(true);
    }
    function closeFilterModal() {
        setFilterModalIsOpen(false);
    }

    function openProductDetailModal() {
        setProductDetailModalIsOpen(true);
    }

    function closeProductDetailModal() {
        setProductDetailModalIsOpen(false);
    }

    function openAllCategoriesModal() {
        setAllCategoriesModalIsOpen(true);
    }
    function closeAllCategoriesModal() {
        setAllCategoriesModalIsOpen(false);
    }
    function closeAllModal() {
        closeCartModal();
        closeAccountModal();
        closeLeftMenuModal();
        closeSearchModal();
        closeAllCategoriesModal();
    }
    const value = useMemo(
        () => ({
            openLeftMenuModal,
            closeLeftMenuModal,
            openAccountModal,
            closeAccountModal,
            openCartModal,
            closeCartModal,
            closeAllModal,
            openFilterModal,
            closeFilterModal,
            openSearchModal,
            closeSearchModal,
            openAddToCartModal,
            closeAddToCartModal,
            openProductDetailModal,
            closeProductDetailModal,
            openAllCategoriesModal,
            closeAllCategoriesModal,
            openRightModal,
            closeRightModal,
            rightModalIsOpen,
            addToCartModalIsOpen,
            leftMenuModalIsOpen,
            accountModalIsOpen,
            cartModalIsOpen,
            filterModalIsOpen,
            searchModalIsOpen,
            productDetailModalIsOpen,
            allCategoriesModalIsOpen,
        }),
        [
            rightModalIsOpen,
            addToCartModalIsOpen,
            leftMenuModalIsOpen,
            accountModalIsOpen,
            cartModalIsOpen,
            filterModalIsOpen,
            searchModalIsOpen,
            productDetailModalIsOpen,
            allCategoriesModalIsOpen,
        ],
    );

    // eslint-disable-next-line
    // const value = {
    //     openLeftMenuModal,
    //     closeLeftMenuModal,
    //     openAccountModal,
    //     closeAccountModal,
    //     openCartModal,
    //     closeCartModal,
    //     closeAllModal,
    //     openFilterModal,
    //     closeFilterModal,
    //     openSearchModal,
    //     closeSearchModal,
    //     openProductDetailModal,
    //     closeProductDetailModal,
    //     openAllCategoriesModal,
    //     closeAllCategoriesModal,
    //     openAddToCartModal,
    //     closeAddToCartModal,
    //     openRightModal,
    //     closeRightModal,
    //     addToCartModalIsOpen,
    //     leftMenuModalIsOpen,
    //     accountModalIsOpen,
    //     cartModalIsOpen,
    //     filterModalIsOpen,
    //     searchModalIsOpen,
    //     productDetailModalIsOpen,
    //     allCategoriesModalIsOpen,
    //     rightModalIsOpen,
    // };

    return (
        <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
    );
};
