function capitalize(word, locale) {
    return word
        .toLowerCase()
        .replace(/\w/, (firstLetter) => firstLetter.toLocaleUpperCase(locale));
}
function capitalizeSentences(sentence, locale) {
    const localeWord = locale === 'en' ? 'en-US' : 'tr-TR';
    const words = sentence.split(' ');
    const newSentence = words.map((word) => capitalize(word, localeWord));
    return newSentence.join(' ');
}
export default capitalizeSentences;
